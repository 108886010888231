import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { projects } from "../data";

const Details = () => {
  const { id } = useParams();
  const {
    title,
    description,
    subImage,
    link,
    video,
    content,
    contentTitle,
    contentClass,
    contentImageTitle,
    contentImage,
    lowPrototypes,
    medPrototypes,
    text,
  } = projects[id];
  return (
    <>
      <div className="flex flex-col items-center">
        <h1 className="mb-10 text-3xl mt-5 font-semibold">{title}</h1>
        <p className="w-4/5 mb-5 max-w-5xl">{description}</p>
        {text && <div className="w-4/5 mb-5 max-w-5xl">{text}</div>}
        {link && (
          <a
            className="underline decoration-2 mb-2 text-rose-500"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            View details
          </a>
        )}

        {subImage && (
          <img src={subImage} alt="under construction" className="w-4/5 mb-5" />
        )}

        {video && (
          <>
            <div
              style={{
                padding: "55% 0 0 0",
                position: "relative",
                width: "80%",
              }}
            >
              <iframe
                src={video}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title={title}
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </>
        )}
      </div>
      {content && (
        <div
          className={`flex flex-col items-center p-8 ${
            contentClass ? contentClass : "bg-slate-100 text-slate-800"
          }`}
        >
          <h3 className="font-bold mb-3">{contentTitle}</h3>
          <p className="">{content}</p>
        </div>
      )}
      {contentImageTitle && (
        <div className="flex flex-col items-center p-3 bg-slate-100 text-neutral-700 ">
          <h3 className="font-bold">{contentImageTitle}</h3>
        </div>
      )}
      {contentImage && (
        <div className="flex flex-col items-center p-1 bg-slate-100">
          <img src={contentImage} alt="content" className="w-4/6 h-4/6"></img>
        </div>
      )}
      {lowPrototypes && <div>{lowPrototypes}</div>}
      {medPrototypes && <div>{medPrototypes}</div>}
    </>
  );
};

export default Details;
