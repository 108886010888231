import React from "react";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Details from "./components/Details";
import { ROUTES } from "./Routes";

export default function App() {
  return (
    <main className="text-neutral-700 bg-slate-50 body-font">
      <Router>
        <Navbar />
        <Switch>
          <Route exact path={ROUTES.home}>
            <About />
            <Projects />
            <Skills />
          </Route>
          <Route path={ROUTES.projectDetails}>
            <Details />
          </Route>
        </Switch>
        <footer className="p-8 bg-teal-300 text-gray-900 flex justify-between">
          <div>
            Made by{" "}
            <a
              className="underline hover:no-underline"
              href="https://www.linkedin.com/in/tendy-su/"
            >
              me
            </a>
          </div>
          <div>
            with assets from{" "}
            <a
              className="underline hover:no-underline"
              href="https://www.heroicons.com"
            >
              heroicons
            </a>
          </div>
        </footer>
      </Router>
    </main>
  );
}
