import { Component } from "react";

class Carousel extends Component {
  state = {
    active: 0,
  };

  static defaultProps = {
    images: ["/goodDoggy.png"],
  };

  handleIndexClick = (event) => {
    this.setState({
      active: +event.target.dataset.index,
    });
  };

  render() {
    const { active } = this.state;
    const { images } = this.props;

    return (
      <div className="carousel flex-col lg:flex-row">
        <img src={images[active]} alt="img" />
        <div className="carousel-smaller flex flex-wrap justify-center">
          {images.map((photo, index) => (
            <div>
              <img
                key={photo}
                src={photo}
                data-index={index}
                onClick={this.handleIndexClick}
                className={index === active ? "active" : ""}
                alt="animal thumbnail"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Carousel;
