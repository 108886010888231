import * as React from "react";

export default function About() {
  return (
    <section id="about">
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col items-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-semibold text-teal-500 text-center">
            Hi, I'm Tendy.
          </h1>

          <img
            className="object-scale-down h-48 w-96 object-center mb-5  "
            alt="tendy"
            src="./tendy.png"
          />
          <p className="mb-8 leading-relaxed">
            Thanks for stopping by! This portfolio is always a work in progress,
            but I'm excited to share with you some of my creative endeavours.
          </p>
        </div>
      </div>
    </section>
  );
}
