import Carousel from "./components/Carousel";

const MAIN_COLOURS = "text-neutral-700 bg-slate-50";

export const projects = [
  {
    title: "MSI selector tool",
    subtitle: "for demo purposes only",
    description:
      "Partnered with a policy advisor to create this proof of concept using React",
    link: "https://msitool.web.app/#intro",
  },
  {
    title: "ErgoCheck risk assessment tool",
    subtitle: "for demo purposes only",
    description:
      "Partnered with an ergonomist to create this proof of concept using Flutter",
    image: "/ergo.png",
    link: "https://ergocheck-test.web.app/#/",
  },
  {
    title: "Findo 2.0",
    subtitle: "personal project",
    description:
      "Findo V1 started as a console-based Java application, was redesigned to a GUI with Java Swing, and Findo V2 is rebuilt with React.",
    image: "/dogfun.png",
    contentImage: "/UML_Design_Diagram.png",
    link: "https://tendy-s.github.io/",
    text: (
      <div>
        <p>
          This project is very special to me because my favourite being in the
          world is a mixed breed rescue named Lady. While she is naturally
          curious and eager to explore, Lady has anxiety issues and tends to get
          overstimulated easily. Dog parks are therefore not an ideal place for
          her to meet other canine friends. Enter Findo- the idea behind this
          application is to enable other dog parents who are in a similar
          situation to efficiently plan casual socialization opportunities for
          their fur babies through carefully curated matches. Findo simplifies
          the socialization process by matching compatible dogs via their unique
          profile. Dog owners will then be able to view the profile of potential
          dog friends and initiate a play date request that will contain details
          such as the meeting day, time, and location. Moreover, users have the
          freedom to edit their dog's profile at any time and see updated
          matches, review their play date requests and confirm/cancel as needed.
        </p>
        <p className="mt-3">
          Find other furry adoptables at{" "}
          <a
            className="underline decoration-2 mb-2 text-rose-500"
            href="https://ihelpedsave.ca/"
            target="_blank"
            rel="noreferrer"
          >
            ihelpedsave
          </a>
        </p>
      </div>
    ),
    content: (
      <div className="max-w-5xl">
        <p className="mb-3">
          <h3 className="text-center text-lg">UML</h3>
          The diagram below illustrates two types of user interactions-
          console-based and graphical user interface (gui). Findo V1 was
          implemented by its gui representation. If I had more time to work on
          the project, I would improve my design through these refactoring
          strategies:
        </p>

        <ul className="list-disc ml-10">
          <li className="mb-3">
            Improve(decrease) coupling by abstracting duplicated code in gui
            classes into methods (e.g. duplicated ui creation code, such as the
            creation of buttons)
          </li>
          <li className="mb-3">
            Increase cohesion and improve SRP by splitting up DogProfile class
            (it currently has too many responsibilities and too many classes
            depend on it) and separate distinct functionalities (e.g.
            addPlayDate()/confirmPlayDate() methods could be in a
            PlayDateManager class instead)
          </li>
          <li className="mb-3">
            Having a PlayDateManager class could potentially decouple several
            workflow classes (e.g. RequestPlayDateworkflowGui) from DogProfile.
          </li>
          <li className="mb-3">
            Instead of having the DogProfile in my workflow gui classes,
            DogProfile could exist as a protected field in BaseWorkflowGui
            instead, allowing its children to inherit the information
          </li>
          <li className="mb-3">
            Leverage the Observer design pattern to improve workflow navigation,
            in order to remove the bi-directional relationships with its related
            workflows and reduce coupling (e.g. whenever a dog profile is loaded
            from file, we could notify the other workflows to update their dog
            profile references to the new dog profile, instead of recreating
            them); the MainMenuWorkflowGui could extend Observable and the other
            workflows could implement Observer
          </li>
          <li className="mb-3">
            Investigate and use a more simplified ui layout strategy; perhaps
            replace GridBagConstraints positioning with GridLayout
          </li>
        </ul>
      </div>
    ),
    video:
      "https://player.vimeo.com/video/685714870?h=457c173eea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Dog adoption",
    subtitle: "Database course",
    description:
      "This PHP/MySQL application models the domain of a dog adoption organization and focuses on data that is involved in the typical process of rescuing a dog and finding them a suitable home, which includes storing and documenting information pertaining to the dogs.",
    image: "/petAdoption.png",
    subImage: "/dog-adoption-er.png",
    video:
      "https://player.vimeo.com/video/685708408?h=23ca59b8a4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    title: "Experience design",
    subtitle: "Grad school project",
    description:
      "Hart house engaged us to redesign how they convey course offerings on their website. Our design was informed by a series of user interviews, design workshops, heuristic evaluation and a survey with 61 respondents.",
    contentTitle: "Understanding the Hart House Registration Process",
    content: (
      <div className="max-w-5xl flex flex-col items-center">
        <ul className="list-disc">
          <li>
            Initial stakeholder meeting ​to understand business goals and define
            scope
          </li>
          <li className="mt-2">
            Identified target users and prepared for user interviews
            <ul className="list-disc ml-5">
              <li>Demographics</li>
              <li>Protocol</li>
              <li>Relevant questions</li>
            </ul>
          </li>
          <li className="mt-2">
            Distributed Survey (for quantitative data and expanding outreach)
            <ul className="list-disc  ml-5">
              <li>Survey monkey</li>
              <li>Incentives (prize draws) </li>
            </ul>
          </li>

          <li className="mt-2">
            Continuous Data Collection
            <ul className="list-disc  ml-5">
              <li>Inventory of Touch Points</li>
              <li>Moments of truth</li>
              <li>Memorable quotes</li>
            </ul>
          </li>

          <li className="mt-2">
            Iterations on experience map
            <ul className="list-disc  ml-5">
              <li>Gathering feedback and validating assumptions</li>
              <li>Recommending business opportunities </li>
            </ul>{" "}
          </li>
        </ul>

        <h3 className="mt-6 mb-3 font-semibold">
          Our UI design is based on the Lean UX approach.
        </h3>

        <p>
          <b>Problem Statement</b>: The Hart House online course registration
          process is intended to achieve the goal of allowing users to
          intuitively explore and register for available creative or fitness
          classes. This would involve removing redundancies and streamlining the
          current process. Secondary goals would be to encourage users to
          consider and/or register for other courses in addition to registering
          for their intended course. We have observed that the product/service
          isn’t meeting the goal of providing an intuitive means of exploring
          course offerings, and providing information in a well-organized way.
          This may be limiting the number of users who complete the registration
          process online, and it is adversely affecting users’ initial and
          overall experience with Hart House.
        </p>
        <ul className="list-disc mt-3">
          <b>
            How might we improve our service so that our customers are more
            successful based on:
          </b>
          <ul className="list-disc ml-5 mb-2">
            <li>More registrations</li>
            <li>
              Spending less time on filling out registration and account
              information
            </li>
            <li>
              Less time clicking through or between pages to accomplish a
              completed registration
            </li>
            <li>Increase in traffic to HH.ca and the registration portal</li>
          </ul>
          <b>User Stories</b>
          <ul className="list-disc ml-5">
            <li>
              As a student I want to engage in more fitness activities so that I
              can get healthier.
            </li>
            <li>
              As a student I want to improve my communication skills so that I
              can gain more confidence and become a better public speaker.
            </li>
            <li>
              As a student I want to become more involved in the U of T
              community so that I can meet new people and make friends outside
              my major.
            </li>
          </ul>
        </ul>
        <ul className="list-disc mt-3">
          <b>Experiment Stories</b>
          <ul className="list-disc ml-5">
            <li>
              We believe that helping new users find the class that’s right for
              them will increase registrations.
            </li>
            <li>Tactic: Class Finder, Customer Interviews</li>
            <li>
              We believe that sending registered users a “lively” and
              appropriately branded confirmation email will decrease confusion
              in the registration process and increase user engagement.
            </li>
            <li>
              Tactic: Survey Results, Confirmation E-mail sent after payment is
              completed
            </li>
            <li>
              We believe that sending users who have registered in a similar
              course before a reminder email will increase their learning
              continuity and motivate them to pursue the next level.
            </li>
            <li>
              Tactic: Survey Results, Reminder E-mail when a similar course is
              open for registration
            </li>
            <li>
              We believe that recommending users related courses as they browse
              the Hart House website will increase the awareness of our
              offerings and contribute to more registrations.
            </li>
            <li>Tactic: System recommendations, Customer Interviews</li>
          </ul>
          <div className="mt-3 max-w-5xl flex flex-col items-center">
            Card Sorting: To understand users' mental model regarding the
            categorization of Hart House offerings
            <img
              src="/hh-cs1.jpeg"
              alt="card sorting"
              className="mb-3 h-4/6 w-4/6"
            ></img>
            <img
              src="/hh-cs2.jpeg"
              alt="card sorting"
              className="mb-3 h-4/6 w-4/6"
            ></img>
          </div>
        </ul>
      </div>
    ),
    image: "/experiencedesign.png",
    subImage: "/hartHouse.png",
    contentImageTitle: "Proto-personas",
    contentImage: "/proto-personas.png",
    lowPrototypes: (
      <div className="flex flex-col min-h-screen justify-center items-center">
        <h3 className="font-semibold p-24">Low fidelity prototypes</h3>
        <Carousel
          images={[
            "/hart-house-low/1.1.png",
            "/hart-house-low/2.0.png",
            "/hart-house-low/3.0.png",
            "/hart-house-low/4.0.png",
            "/hart-house-low/5.0.png",
            "/hart-house-low/6.0.png",
            "/hart-house-low/7.0.png",
            "/hart-house-low/8.0.png",
          ]}
        />
      </div>
    ),
    medPrototypes: (
      <div className="flex flex-col min-h-screen justify-center items-center">
        <h3 className="font-semibold p-24">Medium fidelity prototypes</h3>
        <Carousel
          images={[
            "/Homepage.png",
            "/Class_Finder.png",
            "/Class_detail.png",
            "/Personal_Group_Training.png",
            "/Creative_Classes.png",
            "/Cart_Page.png",
            "/Confirmation_Email.png",
          ]}
        />
      </div>
    ),
  },
  {
    title: "Analysis of credit card client dataset",
    subtitle: "Machine learning course",
    description:
      "An overview of how we cleaned up data, trained our model and analyzed the results. Our primary objective is to predict whether a credit card client will default or not, which could motivate the business to adopt preventative actions.",
    image: "/robots.png",
    contentClass: MAIN_COLOURS,
    content: (
      <div className="flex flex-col justify-center items-center max-w-5xl">
        <h3 className="font-semibold">
          Description of{" "}
          <a href="https://www.kaggle.com/uciml/default-of-credit-card-clients-dataset">
            Default of Credit Card Clients Dataset
          </a>
        </h3>
        This dataset contains 30,000 examples and 24 features (including our
        target column, which is labeled “default.payment.next.month”). Before
        conducting any machine learning, we first split the data into a train
        set and a test set- this is critical for the training and validation
        phase, and ensures that we do not violate the golden rule of machine
        learning, which states that the test data cannot influence training the
        model in any way. We then perform exploratory analysis to acquire an
        understanding of what are potentially useful features. It seems that for
        features education, marriage and pay_x, there are inconsistencies
        between the documentation and the actual data. For instance, there are
        0’s present in all three features but it’s not clear what these
        represent. As we don’t currently have access to the researchers, it’s
        difficult to confirm. However, as indicated by a slightly darker blue
        colour, the correlation heatmap below (Figure 2) suggests there is a
        decent correlation between the pay_x features, education and our target;
        thus, it makes sense for these features to participate in the training
        phase of the model. We also select several numeric features to visualize
        using histograms and see if any stand out in predicting the target class
        (Figure 3 & 4). We drop “marriage”, “sex” to avoid any biases in our
        model and “id” as they are unique identifiers and unlikely to serve a
        meaningful purpose. Finally, we see that class imbalance exists within
        the dataset (see Figure 1); this will influence how we might train our
        model in the next stage.
        <h3 className="mt-3 mb-2 font-semibold">Figure 1: class imbalance</h3>
        <img
          src="/class_imbalance.png"
          alt="class imbalance"
          className="mt-2 w-2/6 h-2/6"
        ></img>
        <h3 className="mt-3 mb-2 font-semibold">
          Figure 2: correlation heatmap
        </h3>
        <img
          src="/correlation_heatmap.png"
          alt="correlation_heatmap"
          className="mt-2 w-4/6 h-4/6"
        ></img>
        <h3 className="mt-3 mb-2 font-semibold">
          Figure 3: histogram of limit_bal by target class
        </h3>
        <img
          src="/limit_bal.png"
          alt="limit bal"
          className="mt-2 w-2/6 h-2/6"
        ></img>
        <h3 className="mt-3 mb-2 font-semibold">
          Figure 4: histogram of age by target class
        </h3>
        <img src="/age.png" alt="age" className="mt-2 w-2/6 h-2/6 mb-3"></img>
        <h3 className="font-semibold">Description of the model</h3>
        After creating pipelines, making comparisons between a baseline and
        various appropriate classifiers, the chosen model is LightGBM. LightGBM
        is a gradient boosted tree model that is particularly notable for its
        speed. Since from our initial data analyses, we discovered that there is
        class imbalance, we address this issue by setting the class_weight to
        “balanced”. We further optimize its hyperparameters by using
        RandomizedSearchCV, which is a particularly useful method when we have
        many parameters to try. Through additional tuning, we are able to
        produce a slightly higher test f1 score of 0.544. Moreover, it is
        beneficial to evaluate which features our model considers to be
        important. For instance, by using a tool called shap, we are given an
        explanation of the impact of important features. In this case, the top
        three are pay_0, limit_bal and bill_amt1 (Figure 3).
        <h3 className="mt-3 mb-2 font-semibold">Figure 5: LGBM and pipeline</h3>
        <img
          src="/param_grid.png"
          alt="param grid"
          className="mt-2 w-3/6 h-3/6"
        ></img>
        <h3 className="mt-3 mb-2 font-semibold">
          Figure 6: RandomizedSearchCV with LGBM results
        </h3>
        <img
          src="/param_grid.png"
          alt="param grid"
          className="mt-2 w-3/6 h-3/6"
        ></img>
        <h3 className="mt-3 mb-2 font-semibold">
          Figure 7: feature importance using SHAP
        </h3>
        <img
          src="/param_grid.png"
          alt="param grid"
          className="mt-2 w-3/6 h-3/6 mb-3"
        ></img>
        <h3 className="font-semibold">Results</h3>
        <p>
          To evaluate our results, we call score on the test set. Using the
          metric “f1 score”, our final test score is 0.53, which also agrees
          with the validation scores we obtained during the training phase
          (approximately 0.544). We trust our results, as we have a fairly large
          dataset, which means overfitting (which is when the model learns the
          detail and noise in the training data too well, thus negatively
          impacting its performance on new, unseen data) is less likely to be a
          concern and our test score is robust. Moreover, our recall for the
          class we are interested in (“True/Default") is fairly decent (0.62).
          This means that the business may be able to more effectively make
          decisions and perhaps send reminders to clients regarding timely
          payments or offer suitable alternatives. By improving the recall
          score, it could also provide a more objective means to determine
          whether an individual would be a responsible customer. Below is a
          classification report that illustrates these relevant numbers (Figure
          5).
        </p>
        <p>
          Overall, this project serves as a solid foundation for any future
          exploration. Although the less relevant “False/No default” class seems
          to have achieved superior scores, we need to keep in mind that is very
          possible for our results to be further improved through other
          interesting techniques, such as feature engineering and adjusting
          prediction thresholds. But we’ll save these ideas for our next machine
          learning adventure!
        </p>
        <h3 className="mt-3 mb-2 font-semibold">
          Figure 8: classification report
        </h3>
        <img
          src="/classification_report.png"
          alt="classification report"
          className="mt-2 w-3/6 h-3/6 mb-3"
        ></img>
        <h3 className="font-semibold">Caveats</h3>
        It is possible that our results may be misleading as we do not have
        access to the researchers responsible for assembling this dataset.
        Consequently, we may have misinterpreted how the features were encoded,
        which may have led us to prioritizing the wrong features. As illustrated
        in Figure X, it is also important to reiterate that class imbalance
        exists within this dataset. A mere 22% of the examples in the training
        set belong to the “True” class. While we have attempted to handle the
        imbalance through setting class_weight to ‘balanced’, there are
        potentially other more efficient methods to address this issue (e.g.
        through undersampling or oversampling, or making the weight of the
        positive class more important).
      </div>
    ),
  },

  {
    title: "Game design",
    subtitle: "Graduate school project",
    description:
      "Can a game that emphasizes sensory and cognitive appeal and ensures good usability positively impact knowledge retention?",
    image: "/taken.png",
    subImage: "/astronaumical.png",
    content: (
      <div className="max-w-5xl">
        <p className="mt-3">
          To find out, we conducted pre- and post-testing, demographic
          questionnaires, observed playtesting, interviews and Likert scale
          surveys to record game quality and educational effectiveness. Our
          results indicate that the lack of extraneous processing achieved
          through good usability improves knowledge retention and generative
          processing.
        </p>

        <h3 className="font-semibold mt-3">Summary</h3>
        <p className="mt-3">
          The usability test for the game Astronaumical sought to measure the
          impact of game mechanics as well as audio-visual and narratological
          elements on its effectiveness as an educational tool. Data on the
          experiential quality of the game, the presentation of its academic
          content, and changes in the academic performance of players were
          gathered and analyzed. Combining qualitative methods, including Likert
          Scale questions, focused interviews, and direct observation with
          quantitative measurements of players’ academic performance in the form
          of within-subject pre- and post-testing, it was shown that aesthetics
          and narrative developed an immersive experience that improved
          knowledge retention. This was shown by the fact that scores improved
          by an average of 40% on a post-test of the academic material compared
          to the pre-test results.
        </p>
      </div>
    ),
  },
  {
    title: "Research methods",
    subtitle: "Graduate school project",
    description:
      "Understanding electric car infrastructure through existing literatures, and doing competitive analysis to inform future designs regarding the integration of an electric vehicle management system to support EV users",
    image: "/research.png",
    subImage: "/electricAve.png",
    content: (
      <div className="max-w-5xl">
        <h3 className="font-semibold"> Research Design: </h3>
        <p className="mt-3">
          This study required a mixed-methods approach that integrated
          quantitative and qualitative data. The quantitative approach served to
          identify the array of physical and digital resources used by electric
          car owners, the current affordances of Toronto’s city planning and
          general statistics. Meanwhile, the qualitative approach includes
          techniques such as usage patterns, subjective criticisms, and
          recommendations that are best understood from a firsthand perspective
          and provided an in-depth understanding of the issues encountered by EV
          owners. These elements were investigated through two divergent yet
          cumulative strategies: a detailed survey questionnaire and a
          research-based comparative analysis.{" "}
        </p>

        <h3 className="font-semibold mt-3"> Research Questions: </h3>
        <ul className="list-decimal">
          <li className="mt-3">
            How does the current charging infrastructure in the city of Toronto
            support electric car users?
          </li>
          <p className="mt-3">
            Data for this question includes defining the existing EV charging
            infrastructure currently in place and comparing it to user preferred
            charging locations, residence areas and workplace locations.
          </p>
          <li className="mt-3">
            What factors influence users’ charging behaviour such that it
            integrates into their daily lives?
          </li>
          <p className="mt-3">
            This research identifies the degree to which existing charging
            infrastructure and charging behaviour are able to be integrated into
            the daily lives of EV users. In other words, the research
            investigated whether users are faced with the inconveniences of
            detouring to find a charging station and waiting for their charge to
            complete, or if they have to park away from their destination solely
            to charge their vehicle. Additional information gathered involves
            the effects of weather, resident location, and other factors on
            charging behaviours. Furthermore, the study investigated whether EV
            users ever experienced “range anxiety” and what kind of effect that
            had on charging behaviour and the user themselves.
          </p>
          <li className="mt-3">
            How available is the information about the locations of these
            charging stations to users?
          </li>
          <p className="mt-3">
            The methods EV owners use to help with finding charging stations can
            have a significant effect on access to charging to stations. As
            such, data focusing on information systems such as mobile
            applications or similar digital technologies have been assessed as
            well as the interaction between the users and these systems.
          </p>
        </ul>
      </div>
    ),
  },
];

export const skills = [
  "TypeScript",
  "React",
  "UX research",
  "User testing",
  "JUnit, Mocha and Chai",
  "HTML/CSS/Tailwind CSS",
  "PHP/MySQL",
  "Chrome DevTools",
  "Accessibility audit",
  "GitHub",
  "Python/Jupyter Notebook",
  "Copywriting",
];
