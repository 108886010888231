import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../Routes";

export default function Navbar() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <header className="bg-rose-400 md:sticky top-0 z-20">
      <div className="container mx-auto flex flex-wrap p-5 flex-row items-center">
        <Link
          to={ROUTES.home}
          className="text-xl title-font font-medium text-white mr-3 "
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? "Tendy Su" : "蘇天柔"}
        </Link>
        <nav className=" border-l border-gray-700	flex flex-wrap items-center text-gray-100 justify-center">
          <a href={ROUTES.projects} className="ml-5 hover:text-white">
            Projects
          </a>
          <a href={ROUTES.skills} className="ml-5 hover:text-white">
            Skills
          </a>
        </nav>
      </div>
    </header>
  );
}
