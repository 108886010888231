import { CodeIcon } from "@heroicons/react/solid";
import React from "react";
import { projects } from "../data";
import { Link } from "react-router-dom";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../Routes";

export default function Projects() {
  return (
    <section
      id="projects"
      className="text-gray-400 bg-gradient-to bg-gradient-to-l from-teal-200 to-teal-500 body-font"
    >
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeIcon className="mx-auto inline-block w-10 mb-4 text-rose-400" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            Projects
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project, id) => (
            <Link
              to={generatePath(ROUTES.projectDetails, { id })}
              key={project.image}
              className="sm:w-1/2 w-100 p-4  min-h-[10rem]"
            >
              <div className="flex relative h-full">
                {/* <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover h-48 w-96 object-center"
                  src={project.image}
                /> */}
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-100 hover:opacity-70">
                  <h2 className="tracking-widest text-sm title-font font-medium text-teal-300 mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
